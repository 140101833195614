<template>
    <div :id="'product_' + product.id" class="col-md-3 col-sm-6 product box" v-if="is_available"  v-bind:class="[product.category == 'gift_basket' ? 'col-xl-4' : 'col-xl-2' ]">
        <div class="card-body mb-3" :class="{ special: product.special }">

            <div class="ribbon ribbon-top-right" v-show="product.special"><span>Special</span></div>
                            
            <div class="ribbon ribbon-top-left" v-show="product.locally_grown">
                <span>
                    Local
                    <img src="/images/canadian-maple-leaf-brands-solid.svg" width="18px" height="18px" class="maple-leaf" alt="Maple Leaf"/>
                </span>
            </div>

            <div class="product-inner">
                <div>
                    <div class="organic_item_ribbon" v-show="product.organic"><span>Organic</span></div>

                    <div class="image">
                        <div class="overlay" @click="handleQuickview">Click to view</div>

                        <router-link :to="'/product/' + product.slug">
                            <img :src="imageUrl(product.image)" :alt="product.title" v-if="product.image" loading="lazy"/>
                        </router-link>
                    </div>

                    <div class="new_item_ribbon" v-show="product.new"><span>New Item</span></div>
                </div>

                <div class="detail">
                    <a href="#" @click.prevent.stop="handlePin" class="pin" :class="{ red: product.pinned }" :title="product.pinned ? 'Unpin' : 'Pin'">
                        <span v-if="product.pinned"><img src="/images/icons/thumb-tack-red.svg" width="24px" height="24px" title="Unpin" /></span>
                        <span v-else><img src="/images/icons/thumb-tack-grey.svg" width="24px" height="24px" title="Pin" /></span>
                    </a>

                    <h3 class="product-title mt-1"><router-link :to="'/product/' + product.slug">{{ product.title }}</router-link></h3>

                    <p class="description" v-html="product.description" v-if="product.category != 'gift_basket'"></p>

                    <span v-if="product.price_2plus">
                        <div class="price2-grn">Buy 2 for {{ price2plus | toCurrency }}</div>
                    </span>

                    <span class="price">
                        <div v-if="product.price_2plus && product.quantity >= 2">
                            <span class="text-muted strikethrough">${{ product.price }}</span>
                            <span class="ml-1 price2-red">${{ product.price_2plus }}/{{ product.size }}</span>
                        </div>
                        <div v-else>${{ product.price }}/{{ product.size }}</div>
                    </span>

                    <product-add-to-cart
                        :product="product"
                        :show-add-to-cart-button="true"
                    ></product-add-to-cart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductAddToCart from '@/components/ProductAddToCart.vue'

    export default {
        props: ['product'],

        components: {
            ProductAddToCart,
        },

        methods: {
            handleQuickview () {
                this.$emit('quickview', this.product);
            },

            imageUrl (image) {
                return image;
            },

            handlePin () {
                this.$store.commit('togglePin', {
                    id: this.product.id,
                    pinned: !this.product.pinned,
                });
            },
        },

        computed: {
            is_available: function() {
                return this.product.active;
            },

            price2plus () {
                if (this.product.price_2plus === null) return false;

                return Math.round(this.product.price_2plus * 2 * 100) / 100;
            },
        },
    }
</script>

<style >
    .product {
        text-align: center;
        margin-bottom: 1.25rem;
        min-height: 165px;
    }

    .product .product-title {
        font-size: 18px;
        margin-bottom: 0.5em;
    }

    .product .product-title a {
        color: #444;
    }

    .product .product-title a:hover {
        text-decoration: underline;
    }

    .product .card-body {
        border: 1px solid rgba(0,0,0,.125);
        display: flex;
        flex-direction: column;
    }

    .product .qty {
        max-width: 65px;
        text-align: center;
        font-size: 18px;
        height: 40px;
        padding: 0 6px;
    }

    .product .image {
        width: 100px;
        margin-right: 1rem;
        max-height: 200px;
        position: relative;
    }

    .product .image:hover {
        cursor: pointer;
    }

    .product .image:hover img {
        opacity: 0.5;
    }

    .product .detail {
        text-align: left;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        position: relative;
    }

    .pin {
        position: absolute;
        transform: rotate(45deg);
        right: 0;
        bottom: -6px;
        z-index: 11;
    }

    .pin:not(.red) {
        opacity: 0.5;
    }

    .price2-grn {
        font-size: 1.1rem;
        font-weight: 500;
        color: #85C71E;
    }

    .price2-red {
        color: red;
    }

    .strikethrough {
        text-decoration: line-through;
    }

    @media (min-width: 768px) {
        .pin {
            bottom: unset;
            top: -30px;
        }

        .product .image:hover .overlay {
            display: block;
        }
    }

    @media (min-width: 1025px) {
        .pin:not(.red) {
            display: none;
        }
    }

    .product:hover a.pin {
        display: block;
    }


    .product img:not(.maple-leaf) {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .product .description {
        font-size: 15px;
        line-height: 1.45;
        flex-grow: 1;
        margin-bottom: 0;
    }

    .product .card-body {
        position: relative;
    }

    .product-inner {
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow: hidden;
    }

    .sold-out {
        font-weight: bold;
        font-size: 14px;
    }

    @media (min-width: 767px) {
        .product {
            margin-bottom: 2rem;
            height: auto;
        }

        .product-inner {
            flex-direction: column;
        }

        .product .qty {
            height: 43px;
        }

        .product .image {
            height: 200px;
            margin-right: 0;
            width: auto;
        }

        .product .detail {
            text-align: center;
        }

        .product .input-group {
            margin-left: auto;
            margin-right: auto;
            width: auto;
        }

        .product .product-title {
            font-size: 20px;
        }

        sold-out {
            font-size: 18px;
        }
    }

    @media (min-width: 1200px) {
        .product.col-xl-2 {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    @media (min-width: 1366px) {
        .product.col-xl-2 {
            flex: 0 0 16.6666666667%;
            max-width: 16.6666666667%;
        }
    }

    .card-body.special {
        border-color: #85C71E;
    }

    .box {
        position: relative;
        max-width: 600px;
        width: 100%;
        max-width: 400px;
        background: #fff;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 767px) {
        .box {
            max-width: 25%;
        }
    }

    .local {
        position: absolute;
    }

    .btn-minus,
    .btn-plus {
        font-size: 1.875rem;
        line-height: 1;
    }

    .fa-thumbtack {
        color: #444444;
    }

    .red .fa-thumbtack {
        color: #FF5047;
    }

    @import url(https://fonts.googleapis.com/css?family=Lato:700);

    .test
    {
        background-color: pink;
        overflow: hidden;
        position: absolute;
        width: 70%;
        height: 10px;
        box-shadow: 0 5px 10px rgba(0,0,0,.1);

    }

    .new_item_ribbon, .organic_item_ribbon {
        overflow: hidden;
        margin-left: 15%;
        zoom: 0.65;
        z-index: 8;
        left: 50%;
        width: 70%;
        filter: drop-shadow(0px 5px 5px rgba(0,0,0,0.15));
    }

    .new_item_ribbon span {
        background-color: #FF5047; /* red */
    }

    .organic_item_ribbon span {
        background-color: #ffc107;  /* yellow */
    }

    .new_item_ribbon span, .organic_item_ribbon span {
        display: block;
        padding: 15px 0;
        padding: 10px 0;
        clip-path: polygon(0 0, 7.5% 50%, 0 100%, 100% 100%, 92.5% 50%, 100% 0);
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        color: #fff;
        font: 700 18px/1 'Lato', sans-serif;
        font: 700 15px/1 'Lato', sans-serif;
        text-shadow: 0 1px 1px rgba(0,0,0,.2);
        text-transform: uppercase;
        text-align: center;
        -webkit-text-size-adjust: 65%;
    }

    /* common */
    .ribbon {
        width: 95px;
        height: 95px;
        overflow: hidden;
        position: absolute;
        zoom: 0.65;
        z-index: 8;
    }
    .ribbon::before,
    .ribbon::after {
        position: absolute;
        content: '';
        display: block;
        border: 5px solid #5C8210;
    }
    .ribbon span {
        position: absolute;
        display: block;
        width: 225px;
        width: 135px;
        padding: 15px 0;
        padding: 10px 0;
        background-color: #85C71E; /* green */
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        color: #fff;
        font: 700 18px/1 'Lato', sans-serif;
        font: 700 15px/1 'Lato', sans-serif;
        text-shadow: 0 1px 1px rgba(0,0,0,.2);
        text-transform: uppercase;
        text-align: center;
        -webkit-text-size-adjust: 65%;
    }
    /* top right*/
    .ribbon-top-right {
        top: -10px;
        right: -10px;
    }
    .ribbon-top-right::before,
    .ribbon-top-right::after {
        border-top-color: transparent;
        border-right-color: transparent;
    }
    .ribbon-top-right::before {
        top: 0;
        left: 15px;
    }
    .ribbon-top-right::after {
        bottom: 15px;
        right: 0;
    }
    .ribbon-top-right span {
        left: 0;
        top: 10px;
        transform: rotate(45deg);
    }
    .ribbon-top-left {
        top: -10px;
        left: -10px;
    }
    .ribbon-top-left::before,
    .ribbon-top-left::after {
        border-color: #cc0000;
        border-top-color: transparent;
        border-left-color: transparent;
    }
    .ribbon-top-left::before {
        top: 0;
        right: 15px;
    }
    .ribbon-top-left::after {
        bottom: 15px;
        left: 0;
    }
    .ribbon-top-left span {
        right: 0;
        top: 10px;
        transform: rotate(-45deg);
        background-color: #FF5047;
    }

    .maple-leaf {
        margin: -2px;
        margin-right: -4px;
    }

    @media (max-width: 767px) {
        .new_item_ribbon, .organic_item_ribbon {
            margin-right: 1rem;
            margin-left: 1rem;
            width: calc(100% - 3rem);
        }
    }

    @media (min-width: 767px) {
        .new_item_ribbon, .organic_item_ribbon, .ribbon {
            zoom: 0.8;
        }

        .box {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .overlay {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 3.95rem 0;
        font-weight: bold;
        text-shadow: 0px 1px 3px #FFF;
        color: #555;
        z-index: 10;
    }
</style>
